'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('StockCtrl', ['$scope', '$routeParams', '$controller', 'Stocks', 'jqplot_weekends', 'prepareQuotes', 'detailedChartOptions', 'XIRRTransactions', 'toggleTripPoint', 'price_point_types',
    function($scope, $routeParams, $controller, Stocks, jqplot_weekends, prepareQuotes, detailedChartOptions, XIRRTransactions, toggleTripPoint, price_point_types) {
        function getStock() {
            return _.tap(Stocks.one($routeParams.id).get(), function(promise){
                promise.then(function(stock) {
                    var quotes = prepareQuotes(stock.quotes);
                    $scope.amount = 0;
                    $scope.deposit_quote = _.first(stock.quotes); // not accurate, but the best we can do
                    var transactions = _.map(stock.transactions, function(transaction) {
                        var price = transaction.price;
                        var point, quote;
                        if (angular.isUndefined(price)) {
                            price = _.result(_.find(stock.quotes, {'date': transaction.book_date}), 'price');
                        }
                        if (angular.isUndefined(price) && transaction.transaction_type == 'DEPOSIT') {
                            price = _.result($scope.deposit_quote, 'price');
                        }
                        if (angular.isUndefined(price)) {
                            return false;
                        }
                        if ($scope.amount < 0.01) {
                            $scope.paid = 0;
                        }
                        if (transaction.transaction_type == 'BUY') {
                            $scope.paid += -transaction.cash_delta;
                        }
                        if (transaction.transaction_type == 'DEPOSIT') {
                            $scope.paid += price * transaction.participations;
                        }
                        if (transaction.transaction_type == 'SELL') {
                            $scope.paid *= (1 - (-transaction.participations / $scope.amount));
                        }
                        if (!angular.isUndefined(transaction.participations)) {
                            $scope.amount += transaction.participations;
                        }
                        point = [transaction.book_date, price];
                        point.transaction = transaction;
                        return point;
                    });
                    if ($scope.amount < 0.01) {
                        $scope.paid = 0;
                    }
                    transactions = _.filter(transactions);
                    $scope.last_quote = _.last(stock.quotes);
                    $scope.quotesStartDate = new Date(_.first(stock.quotes).date);
                    $scope.quotesEndDate = new Date($scope.last_quote.date);
                    $scope.minDisplayDate = $scope.quotesStartDate;
                    $scope.series = [quotes, transactions].concat(_.map($scope.extra_graphs, 'data'));
                    $scope.quote_buy_hint_date = _.find(stock.quotes, {'date': stock.buy_hint_date});
                    $scope.quote_sell_hint_date = _.find(stock.quotes, {'date': stock.sell_hint_date});

                    $scope.stopLoss = $scope.paid / $scope.amount;
                    if (stock.transactions.length) {
                        $scope.XIRRResult = XIRRTransactions({
                            transactions: stock.transactions,
                            amount: $scope.amount,
                            last_quote: $scope.last_quote,
                            deposit_quote: $scope.deposit_quote,
                        });
                    } else {
                        $scope.XIRRResult = undefined;
                    }
                });
            }).$object;
        }

        $scope.$watch('user.isAuthenticated', function() {
            $scope.stock = getStock();
        });
        $scope.chartOptions = detailedChartOptions;
        $scope.series = [[[]], [[]]];
        $scope.chartColors = $.jqplot.config.defaultColors;
        $scope.colorPickerOptions = { columns: 14 };

        $controller('TransactionHighlightController', {$scope:$scope});
        $controller('ExtraGraphsController', {$scope:$scope});

        $scope.showPeriod = '-3 month';
        $controller('ShowPeriodController', {$scope:$scope});
        $scope.$watch('minDisplayDate', function(value) {
            if (angular.isDate(value)) {
                $scope.chartOptions.canvasOverlay.objects = jqplot_weekends(value, new Date());
                $scope.chartOptions.canvasOverlay.objects.push({
                    horizontalLine: {
                        y: $scope.stopLoss,
                        lineWidth: 1,
                        xOffset: 0,
                        color: 'rgb(89, 198, 154)',
                        shadow: false
                    }
                });
                // load more quotes
            }
        });
        var orig_min = undefined;
        var orig_max = undefined;
        $scope.wheel = function($event, $params) {
            $event.preventDefault();
            var $event = $params[0], $delta = $params[1], $deltaX = $params[2], $deltaY = $params[3], plot = $params[4];
            $event.preventDefault();
            var offsetY = 1 - $event.originalEvent.offsetY / $event.originalEvent.target.height;

            if (angular.isUndefined(orig_min)) {
                orig_min = plot.axes.yaxis.min;
                orig_max = plot.axes.yaxis.max;
            }
            if (angular.isUndefined(this.chartOptions.axes.yaxis.min)) {
                this.chartOptions.axes.yaxis.min = plot.axes.yaxis.min;
            }
            if (angular.isUndefined(this.chartOptions.axes.yaxis.max)) {
                this.chartOptions.axes.yaxis.max = plot.axes.yaxis.max;
            }
            var diff = this.chartOptions.axes.yaxis.max - this.chartOptions.axes.yaxis.min;
            /* zoom */
            if ($delta > 0 && diff >= 1) {
                /* center on cursor */
                this.chartOptions.axes.yaxis.min = this.chartOptions.axes.yaxis.min + diff * offsetY - diff / 2;
                this.chartOptions.axes.yaxis.max = this.chartOptions.axes.yaxis.min+diff;

                /* zoom in */
                this.chartOptions.axes.yaxis.min += diff/10;
                this.chartOptions.axes.yaxis.max -= diff/10;
            } else if ($delta < 0) {
                /* zoom out */
                this.chartOptions.axes.yaxis.min -= diff/10;
                this.chartOptions.axes.yaxis.max += diff/10;
            }
            this.chartOptions.axes.yaxis.min = Math.floor10(this.chartOptions.axes.yaxis.min, -1);
            this.chartOptions.axes.yaxis.max = Math.ceil10(this.chartOptions.axes.yaxis.max, -1);
            if (this.chartOptions.axes.yaxis.min < orig_min) {
                this.chartOptions.axes.yaxis.min = undefined;
            }
            if (this.chartOptions.axes.yaxis.max > orig_max) {
                this.chartOptions.axes.yaxis.max = undefined;
            }
        };
        $scope.toggleFavorite = function(stock) {
            stock.favorite = !stock.favorite;
            stock.patch({'favorite': stock.favorite});
        };
        $scope.changeName = function(name) {
            var data = {name: name};
            return Stocks.one($scope.stock.id).all('change_name').patch(data);
        };
        $scope.changeToAltName = function(id) {
            var altName = _.find($scope.stock.alternative_stock_names, {id: id});
            var data = {id: id};
            Stocks.one($scope.stock.id).all('change_to_alt_name').patch(data).then(function() {
                var name = $scope.stock.name;
                $scope.stock.name = altName.name;
                altName.name = name;
            });
        };
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.trip_point_date_open = true;
        };
        $scope.price_point_types = price_point_types;
        $scope.addTripPoint = function(trip_point) {
            Stocks.one($scope.stock.id).all('trip_points').post(trip_point).then(function(trip_point) {
                $scope.stock.trip_points.push(trip_point);
            });
        };
        $scope.toggleTripPoint = toggleTripPoint;
        $scope.addnote = function(note) {
            var scope = this;
            console.log(note);
            Stocks.one($scope.stock.id).all('notes').post({note: note}).then(function(note) {
                $scope.stock.notes.push(note);
                scope.note = '';
            });
        };
        $scope.download = function() {
            var series = _.map($scope.series, function(serie) {
                return _.zipObject(serie); // lodash v4 fromPairs
            });
            var lines = _.map(series.shift(), function(value, date) {
                var other = _.map(series, _.bind(_.result, null, _, date, undefined));
                return date+','+value+other.join(',');
            });
            var headers = ['quotes', 'transactions'].concat(_.map($scope.extra_graphs, function(graph) {
                return graph.type+'-'+JSON.stringify(graph.settings);
            }));
            lines.unshift('"'+headers.join('","')+'"');
            var content = lines.join("\n");
            download(content, $scope.stock.name+'.csv');
        };
    }]);

function download(content, filename) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
}
function download2(content, filename, contentType)
{
    if(!contentType) contentType = 'application/octet-stream';
    var a = document.createElement('a');
    var blob = new Blob([content], {'type':contentType});
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
}


})();
