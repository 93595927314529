'use strict';

/* App Module */

var RobecoApp = angular.module('RobecoApp', [
  'ngRoute',
  'ui.chart',
  'angular-slidezilla',
  'ngjsColorPicker',
  'angular-visible',
  'ui',
  'ngRateIt',
  'angularMoment',
  'xeditable',
  'ui.bootstrap',

  'roControllers',
  'roServices',
  'roDirectives'
]);

RobecoApp.constant('baseUrl', location.pathname);
RobecoApp.constant('debug', location.pathname == '/app_dev.php/');
RobecoApp.constant('partials_path', '/');

RobecoApp.config(['$locationProvider', '$routeProvider', 'RestangularProvider', 'partials_path', 'baseUrl',
  function($locationProvider, $routeProvider, RestangularProvider, partials_path, baseUrl) {
    //$locationProvider.html5Mode({enabled: true, requireBase: false}); //Remove the '#' from URL.
    $routeProvider.
      when('/', {
        title: 'Home',
        templateUrl: partials_path + 'partials/home.html',
      }).
      when('/assets', {
        title: 'Assets',
        templateUrl: partials_path + 'partials/asset-list.html',
        controller: 'AssetListCtrl'
      }).
      when('/stocks', {
        title: 'Stocks',
        templateUrl: partials_path + 'partials/stock-list.html',
        controller: 'StockListCtrl'
      }).
      when('/stock/:id/:name*', {
        templateUrl: partials_path + 'partials/stock.html',
        controller: 'StockCtrl',
      }).
      when('/stock/:id', { // alternative
        templateUrl: partials_path + 'partials/stock.html',
        controller: 'StockCtrl',
      }).
      when('/transactions', {
        title: 'Transactions',
        templateUrl: partials_path + 'partials/transaction-list.html',
        controller: 'TransactionListCtrl'
      }).
      when('/trippoints', {
        title: 'Trip points',
        templateUrl: partials_path + 'partials/tripPoint-list.html',
        controller: 'TripPointListCtrl'
      }).
      when('/notes', {
        title: 'Notes',
        templateUrl: partials_path + 'partials/note-list.html',
        controller: 'NoteListCtrl'
      }).
      when('/profile', {
        title: 'Profile',
        templateUrl: partials_path + 'partials/profile.html',
        controller: 'ProfileController'
      }).
      otherwise({
        redirectTo: '/'
      });

    RestangularProvider.setBaseUrl(baseUrl);
    RestangularProvider.setRequestSuffix('.json');
    RestangularProvider.setDefaultHttpFields({cache: true});

    $.jqplot.config.enablePlugins = true;
    $.jqplot.config.defaultColors = $.jqplot.config.defaultColors.concat([
        '#7bd148', '#5484ed', '#a4bdfc', '#46d6db', '#7ae7bf', '#51b749',
        '#fbd75b', '#ffb878', '#ff887c', '#dc2127', '#dbadff', '#e1e1e1'
        ]).sort();
    $.jqplot.postDrawHooks.push(function() {
        var plot = this;
        Hamster(this.grid._elem.context).wheel(function($event, $delta, $deltaX, $deltaY) {
            var evt = jQuery.Event('rwheel');
            plot.target.trigger(evt, [$event, $delta, $deltaX, $deltaY, plot]);
        });
    });

    (function() {
    /**
    * Decimal adjustment of a number.
    *
    * @param {String}  type  The type of adjustment.
    * @param {Number}  value The number.
    * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
    * @returns {Number} The adjusted value.
    */
    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    // Decimal round
    if (!Math.round10) {
        Math.round10 = function(value, exp) {
        return decimalAdjust('round', value, exp);
        };
    }
    // Decimal floor
    if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
        return decimalAdjust('floor', value, exp);
        };
    }
    // Decimal ceil
    if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
        return decimalAdjust('ceil', value, exp);
        };
    }
    })();
  }])
  .run(['Restangular', 'RestangularErrorInterceptor', 'RestangularAuthRequestInterceptor', 'RestangularAuthResponseInterceptor',
  function(Restangular, RestangularErrorInterceptor, RestangularAuthRequestInterceptor, RestangularAuthResponseInterceptor) {
    Restangular.setErrorInterceptor(RestangularErrorInterceptor);
    Restangular.addFullRequestInterceptor(RestangularAuthRequestInterceptor);
    Restangular.addResponseInterceptor(RestangularAuthResponseInterceptor);
  }])
  .run(['$rootScope', 'Auth', function($rootScope, Auth) {
    $rootScope.toArray = _.toArray;
    $rootScope.range = _.range;
    $rootScope.findWhere = _.findWhere;
    $rootScope.setTitle = function(title) {
        $rootScope.title = title;
    };
    $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
        if (!angular.isUndefined(current) && !angular.isUndefined(current.$$route)) {
            $rootScope.title = current.$$route.title;
        } else {
            $rootScope.title = '';
        }
    });
    $rootScope.user = Auth;
  }])
  .run(['editableOptions', function(editableOptions) {
      editableOptions.theme = 'bs3';
  }]);

//We already have a limitTo filter built-in to angular,
//let's make a startFrom filter
RobecoApp.filter('startFrom', function() {
    return function(input, start) {
        start = +start; //parse to int
        return input.slice(start);
    }
})
.filter('toArray', function () {
    return function (obj) {
        return _.values(obj);
    }
});
