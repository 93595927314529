'use strict';

/* Services */
(function() {

var module = angular.module('roServices', ['restangular']);

module.factory('Stocks', function(Restangular) {
    var service = Restangular.service('stocks');
    Restangular.extendModel('stocks', function(model) {
            model.getQuotes = function(from, to) {
                if (_.isUndefined(this.quotes)) {
                    this.quotes = this.getList('quotes', { from: from }).$object;
                }
                return this.quotes;
            };
            return model;
    });
    return service;
});
module.factory('Transactions', function(Restangular) {
    var service = Restangular.service('transactions');
    return service;
});
module.factory('TripPoints', function(Restangular) {
    var service = Restangular.service('trippoints');
    return service;
});
module.factory('Notes', function(Restangular) {
    var service = Restangular.service('notes');
    return service;
});
module.factory('Users', function(Restangular) {
    var service = Restangular.service('users');
    service.login = function(username, password) {
        return Restangular.allUrl('login', 'login_check').post({'_username': username, '_password': password});
    };
    return service;
});

module.factory('$exceptionHandler', ['$log', 'debug', function ($log, debug) {
  return function (exception, cause) {
    if (!_.isUndefined(cause)) {
        exception.message += ' (caused by "' + cause + '")';
    }
    $log.error.apply($log, arguments);
    if (debug) {
        alert(exception);
    }
  };
}]);

module.factory('RestangularErrorInterceptor', ['Auth', '$http', '$log', 'debug', function (Auth, $http, $log, debug) {
  return function (response, deferred, responseHandler) {
    var error = response.statusText + ': ' + response.config.method + ' ' + response.config.url;
    var exception;

    if (response.status === 401) {
      Auth.logout();
      return;
    }
    if (response.status === 404) {
      $log.warn.call($log, error);
      if (debug) {
        alert(error);
      }
      return;
    }
    if (response.status === 500) {
      for (var i=0; i < response.data.error.exception.length; i++) {
        exception = response.data.error.exception[i];
        $log.error.call($log, error +"\n"+ exception.class+': '+exception.message + "\n%o", exception.trace);
        if (debug) {
          alert(error +"\n"+ exception.class+': '+exception.message);
        }
      }
      return false;
    }
  };
}]);

module.factory('toggleTripPoint', ['Stocks', function(Stocks) {
    return function(trip_point, stock_id) {
        Stocks.one(stock_id).one('trip_points', trip_point.id)
            .one(!trip_point.enabled ? 'enable' : 'disable').patch({})
            .then(function(trip_point) {
            });
    };
}]);
module.constant('price_point_types', {
    avg: 'Average',
    avg2: 'Average 2:1',
    fixed: 'Fixed',
    'avg-tripped': 'Average updates only when not tripped',
    'avg2-tripped': 'Average 2:1 updates only when not tripped',
});

module.constant('XIRRTransactions', function(asset) {
    var values = [], dates = [];
    _.forEach(asset.transactions, function(transaction) {
        switch(transaction.transaction_type) {
            case 'BUY':
                dates.push(transaction.book_date);
                values.push(-transaction.cash_delta);
                break;
            case 'SELL':
            case 'DIVIDEND':
                dates.push(transaction.book_date);
                values.push(-transaction.cash_delta);
                break;
            case 'DEPOSIT':
                if (!angular.isUndefined(asset.deposit_quote)) {
                    dates.push(asset.deposit_quote.date);
                    values.push(asset.amount * asset.deposit_quote.price);
                }
                break;
        }
    });
    if (asset.amount > 0 && asset.last_quote.price) {
        dates.push(asset.last_quote.date);
        values.push(asset.amount * -asset.last_quote.price);
    }
    return XIRR(values, dates);
});
module.constant('jqplot_weekends', function(start, end, options = {}) {
    var date;
    var weekends = [];
    start = angular.copy(start);
    // 0 -1 *
    // 1 +5 6-1
    // 2 +4 6-2
    // 3 +3 6-3
    // 4 +2 6-4
    // 5 +1 6-5
    // 6 0  =
    if (start.getDay() == 0) {
        start.setDate(start.getDate() - 1);
    } else if (start.getDay() != 6) {
        start.setDate(start.getDate() + 6 - start.getDay());
    }
    start.setHours(-12);
    options = $.extend(true, {
        yOffset: 0,
        color: 'rgba(255, 0, 0, 0.25)',
    }, options);
    while (start < end) {
        date = angular.copy(start);
        date.setDate(date.getDate() + 2);
        weekends.push({
            rectangle: $.extend(true, {}, options,
            {
                xmin : start.getTime(),
                xmax : date.getTime(),
            })
        });
        start.setDate(start.getDate() + 7);
    }
    return weekends;
});

module.constant('prepareQuotes', function(quotes) {
    var result = _.map(quotes, function(quote) {
        var point = [quote.date, quote.price];
        point.quote = quote;
        return point;
    });
    if (result.length == 0) {
        return [[]];
    }
    return result;
});

module.constant('smallChartOptions', {
    seriesDefaults: {
        showMarker: false,
        trendline: {
            show: true
        },
        pointLabels: {
            show: false
        }
    },
    height: 100,
    axes: {
        yaxis: {
            tickOptions: {
                show:false
            }
        },
        xaxis: {
            tickOptions: {
                showLabel:false,
                showMark:false
            },
            max: new Date(),
            renderer: $.jqplot.DateAxisRenderer
        }
    },
    highlighter: {
        show: false
    },
    cursor: {
        show: false
    }
});

module.constant('transactionColors', {
    DIVIDEND: 'orange',
    BUY: '#00ff00',
    SELL: 'red'
});
module.factory('transactionMarkerOptionsEditor', ['transactionColors', function(transactionColors) {
    return function(point, markerOptions) {
        var transaction = point.transaction;
        markerOptions.color = _.result(transactionColors, transaction.transaction_type, null);
        return markerOptions;
    };
}]);
module.factory('detailedChartOptions', ['transactionMarkerOptionsEditor', function(transactionMarkerOptionsEditor) {
    return {
        seriesDefaults: {
            //showMarker: false,
            trendline: {
                show: false
            },
            pointLabels: {
                show: false
            }
        },
        series: [
            {},
            {
                color: '#fbd75b',
                renderer: $.jqplot.PointRenderer,
                rendererOptions: { markerOptionsEditor: transactionMarkerOptionsEditor },
                showLine: false
            }
        ],
        axes: {
            yaxis: {
                tickOptions: {
                    //show:false
                }
            },
            y2axis: {
                min: 0,
                max: 100,
                pad: 0,
                drawMajorGridlines: false,
            },
            xaxis: {
                max: new Date(),
                renderer: $.jqplot.DateAxisRenderer
            }
        },
        canvasOverlay: {
            show: true,
            objects: []
        },
        highlighter: {
            show: true,
            sizeAdjust: 10,
            tooltipLocation: 'n',
            tooltipAxes: 'y',
            tooltipFormatString: '%.2f',
            useAxesFormatters: false,
            tooltipContentEditor: function(str, seriesIndex, pointIndex, plot) {
                var quote, transaction;
                if (seriesIndex == 0) {
                    quote = this.currentNeighbor.data.quote;
                    str = quote.date+': '+str;
                }
                if (seriesIndex == 1) {
                    transaction = this.currentNeighbor.data.transaction;
                    str = transaction.book_date+': '+str+' ('+transaction.transaction_type+')';
                }
                return str;
            }
        },
        cursor: {
            zoom: true,
            looseZoom: true,
            showVerticalLine: true,
            showHorizontalLine: true,
            constrainZoomTo: 'x',
            show: true
        }
    };
}]);

})();
