'use strict';

/* Services */
(function() {

var module = angular.module('roServices');
module.factory('Auth', ['$window', '$rootScope', '$cacheFactory', function($window, $rootScope, $cacheFactory) {
    var self = this;
    self.parseJwt = function(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse($window.atob(base64));
    };
    self.saveToken = function(token) {
      $window.localStorage['jwtToken'] = token;
      self.refresh();
      $cacheFactory.get('$http').removeAll();
    };
    self.getToken = function() {
      return $window.localStorage['jwtToken'];
    };
    self.isAuthed = function() {
      var token = self.getToken();
      if(token) {
        var params = self.parseJwt(token);
        return Math.round(new Date().getTime() / 1000) <= params.exp;
      } else {
        return false;
      }
    };
    self.getUserInfo = function() {
      var token = self.getToken();
      if(token) {
        return self.parseJwt(token);
      }
      return {};
    };
    self.logout = function() {
      $window.localStorage.removeItem('jwtToken');
      self.refresh();
      $cacheFactory.get('$http').removeAll();
    };
    self.refresh = function() {
      self.isAuthenticated = self.isAuthed();
      if (self.isAuthenticated) {
          self.info = self.getUserInfo();
      } else {
          self.info = {};
      }
    };
    self.refresh();
    $window.addEventListener('storage', function(e) {
      if (e.key == 'jwtToken') {
        self.refresh();
        $cacheFactory.get('$http').removeAll();
        $rootScope.$apply();
      }
    });
    return self;
}]);

module.factory('RestangularAuthResponseInterceptor', ['Auth', function (Auth) {
    return function(data, operation, what, url, response, deferred) {
        if (data.token) {
            Auth.saveToken(data.token);
        }
        return data;
    };
}]);
module.factory('RestangularAuthRequestInterceptor', ['Auth', function (Auth) {
    return function(element, operation, what, url, headers, params, httpConfig) {
        if (Auth.isAuthed()) {
            headers.Authorization = 'Bearer ' + Auth.getToken();
        }
        return { headers: headers };
    };
}]);

})();
