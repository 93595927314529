'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('NoteListCtrl', ['$scope', 'Notes',
    function($scope, Notes) {
        $scope.$watch('user.isAuthenticated', function() {
            $scope.notes = Notes.getList().$object;
        });
    }]);

})();
