/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/angular/angular-csp.css';
import './jquery.jqplot.1.0.8r1250/jquery.jqplot.css';
import './angular-slidezilla-0.1.3/src/angular-slidezilla.css';
import '../node_modules/ngjs-color-picker/css/ngjs-color-picker.css';
import '../node_modules/angular-ui/build/angular-ui.css';
import '../node_modules/angular-rateit/dist/ng-rateit.css';
import '../node_modules/angular-xeditable/dist/css/xeditable.css';
import './styles/app.css';

import $ from 'jquery';
import '../node_modules/angular/angular.js';
import '../node_modules/angular-ui-bootstrap/ui-bootstrap-tpls.js';
import '../node_modules/angular-route/angular-route.js';
import '../node_modules/lodash/index.js';
import '../node_modules/restangular/dist/restangular.js';
import './jquery.jqplot.1.0.8r1250/jquery.jqplot.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.canvasOverlay.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.cursor.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.dateAxisRenderer.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.highlighter.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.trendline.js';
import './jquery.jqplot.1.0.8r1250/plugins/jqplot.pointLabels.js';
import '../node_modules/angular-ui-chart/src/chart.js';
import './angular-slidezilla-0.1.3/src/angular-slidezilla.js';
import '../node_modules/ngjs-color-picker/js/ngjs-color-picker.js';
import '../node_modules/angular-visible/dist/angular-visible.js';
import '../node_modules/angular-ui/build/angular-ui.js';
import '../node_modules/angular-rateit/dist/ng-rateit.js';
import moment from 'moment';
global.moment = moment;
import '../node_modules/angular-moment/angular-moment.js';
import XIRR from './XIRR.js';
global.XIRR = XIRR;
import '../node_modules/angular-xeditable/dist/js/xeditable.js';
import Hamster from 'hamsterjs';
global.Hamster = Hamster;

import './js/jqplot.pointRenderer.js';
import './js/app.js';
import './js/controllers.js';
import './js/controllers/AssetListCtrl.js';
import './js/controllers/NoteListCtrl.js';
import './js/controllers/StockListCtrl.js';
import './js/controllers/StockCtrl.js';
import './js/controllers/TransactionListCtrl.js';
import './js/controllers/TripPointListCtrl.js';
import './js/services.js';
import './js/services/auth.js';
import './js/directives.js';
