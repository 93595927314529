'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('StockListCtrl', ['$scope', 'Stocks', 'prepareQuotes', 'smallChartOptions',
    function($scope, Stocks, prepareQuotes, smallChartOptions) {
        var date = new $.jsDate();
        $scope.orderProp = 'name';
        $scope.$watch('user.isAuthenticated', function() {
            $scope.stocks = Stocks.getList().$object;
        });

        $scope.chartOptions = smallChartOptions;

        $scope.prepare = prepareQuotes;
        date.add(-3, 'month');
        $scope.from = date.strftime('%Y-%m-%d');

        $scope.toggleFavorite = function(stock) {
            stock.favorite = !stock.favorite;
            stock.patch({'favorite': stock.favorite});
        };

        $scope.toggleBuyable = function(stock) {
            stock.buyable = !stock.buyable;
            stock.patch({'buyable': stock.buyable});
        };

        $scope.$on("visible", function(e, data) {
            _.forEach(data, function(element) {
                var scope = $(element).scope();
                scope.show_chart = true;
                scope.$digest();
            });
        });
        $scope.$watchGroup(["query", "orderProp"], function () {
            $scope.$emit('ngRepeatFinished');// hack angular-visible
        });
    }]);


})();
