'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('TripPointListCtrl', ['$scope', 'TripPoints', 'toggleTripPoint', 'price_point_types',
    function($scope, TripPoints, toggleTripPoint, price_point_types) {
        $scope.$watch('user.isAuthenticated', function() {
            $scope.trip_points = TripPoints.getList().$object;
        });
        $scope.toggleTripPoint = toggleTripPoint;
        $scope.price_point_types = price_point_types;
    }]);


})();
