'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('AssetListCtrl', ['$scope', 'Transactions', 'Stocks', 'XIRRTransactions',
    function($scope, Transactions, Stocks, XIRRTransactions) {
        function loadTransactions(transactions) {
            _.forEach(transactions, function(transaction) {
                if (_.isUndefined(transaction.stock)) {
                    return;
                }
                var asset = $scope.assets[transaction.stock.id];
                if (_.isUndefined(asset)) {
                    asset = {
                        stock: transaction.stock,
                        paid: 0,
                        trans_dates: [],
                        amount: 0,
                        dividend: 0,
                        result: 0,
                        last_quote: Stocks.one(transaction.stock.id).one('last_quote').get().$object,
                        transactions: [],
                    };
                    $scope.assets[transaction.stock.id] = asset;
                }
                asset.transactions.push(transaction);
                switch(transaction.transaction_type) {
                    case 'BUY':
                        asset.paid += -transaction.cash_delta;
                        asset.amount += transaction.participations;
                        asset.trans_dates.push(transaction.book_date);
                        break;
                    case 'SELL':
                        asset.paid *= (asset.amount + transaction.participations);
                        asset.result += transaction.cash_delta;
                        asset.amount += transaction.participations;
                        asset.trans_dates.push(transaction.book_date);
                        break;
                    case 'DIVIDEND':
                        asset.dividend += transaction.cash_delta;
                        break;
                    case 'DEPOSIT':
                        asset.amount += transaction.participations;
                        break;
                }
            });
        }
        $scope.orderProp = 'name';
        $scope.XIRR = XIRRTransactions;
        $scope.$watch('user.isAuthenticated', function() {
            $scope.assets = {};
            Transactions.getList().then(loadTransactions);
        });
    }]);


})();
