(function($) {

    $.jqplot.PointRenderer = function(){
        $.jqplot.LineRenderer.call(this);
    };

    $.jqplot.PointRenderer.prototype = Object.create($.jqplot.LineRenderer.prototype);
    $.jqplot.PointRenderer.prototype.constructor = $.jqplot.PointRenderer;

    // called with scope of a series
    $.jqplot.PointRenderer.prototype.init = function(options, plot) {
        options = options || {};
        this.renderer.markerOptionsEditor = false;
        $.jqplot.LineRenderer.prototype.init.apply(this, arguments);
        this._type = 'point';
    }

    // called within scope of series.
    $.jqplot.PointRenderer.prototype.draw = function(ctx, gd, options, plot) {
        var i;
        // get a copy of the options, so we don't modify the original object.
        var opts = $.extend(true, {}, options);
        var markerOptions = opts.markerOptions;
        ctx.save();
        if (gd.length) {
            // draw the markers
            for (i=0; i<gd.length; i++) {
                if (gd[i][0] != null && gd[i][1] != null) {
                    if (this.renderer.markerOptionsEditor) {
                        markerOptions = $.extend(true, {}, opts.markerOptions);
                        markerOptions = this.renderer.markerOptionsEditor.call(plot, this.data[i], markerOptions);
                    }
                    this.markerRenderer.draw(gd[i][0], gd[i][1], ctx, markerOptions);
                }
            }
        }

        ctx.restore();
    };

})(jQuery);
