'use strict';

/* Controllers */
(function() {
var module = angular.module('roControllers', [
    'roServices',
]);

module.controller('LoginController', ['$scope', '$timeout', 'Auth', 'Users', function($scope, $timeout, Auth, Users) {
    $scope.login = function() {
        Users.login(this.username, this.password).catch(function(reason) {
            $scope.failed = reason;
            $timeout(function() {
                $scope.failed = false;
            }, 1000);
        });
    };
    $scope.logout = function() {
        Auth.logout();
    };
}]);
module.controller('ProfileController', ['$scope', '$timeout', 'Auth', 'Users', function($scope, $timeout, Auth, Users) {
    $scope.profile = Users.one('me').get().$object;
    $scope.$watch('user.isAuthenticated', function() {
        $scope.profile = Users.one('me').get().$object;
    });
    $scope.checkName = function(name) {
        if (name.trim() === '') {
            return "Can't be empty";
        }
    };
    $scope.checkEMail = function(email) {
    };
    $scope.checkXMPP = function(xmpp_address) {
    };
    $scope.saveProfile = function() {
        $scope.profile.customPUT($scope.profile, 'me').then(
            function() {
                $scope.global_errors = [];
                $scope.errors = [];
            },
            function(response) {
                if (response.status == 400) {
                    $scope.global_errors = response.data.errors.errors;
                    $scope.errors = response.data.errors.children;
                }
            }
        );
    };
}]);
module.controller('ShowPeriodController', ['$scope', function($scope) {
        $scope.minDisplayDate = new Date();
        $scope.$watch('chartOptions.axes.xaxis.min', function(value) {
            if (angular.isObject(value) && angular.isDate(value.proxy) && ($scope.minDisplayDate > value.proxy)) {
                $scope.minDisplayDate = value.proxy;
            }
        });
        $scope.$watch('showPeriod', function(value) {
            var date = new $.jsDate();
            switch (value) {
                case '-1 month':
                    date.add(-1, 'month');
                    $scope.chartOptions.axes.xaxis.min = date;
                    $scope.chartOptions.canvasOverlay.show = true;
                    break;
                case '-3 month':
                    date.add(-3, 'month');
                    $scope.chartOptions.axes.xaxis.min = date;
                    $scope.chartOptions.canvasOverlay.show = true;
                    break;
                case '-1 year':
                    date.add(-1, 'year');
                    $scope.chartOptions.axes.xaxis.min = date;
                    $scope.chartOptions.canvasOverlay.show = false;
                    break;
                default:
                    $scope.chartOptions.axes.xaxis.min = null;
                    $scope.chartOptions.canvasOverlay.show = false;
            }
        });
}]);

module.controller('ExtraGraphsController', ['$scope', '$routeParams', 'Stocks',
    function($scope, $routeParams, Stocks) {
        var graphFunctions = {
            sellStopLoss: {
                label: 'sellStopLoss',
                settings: [ 0.979, 0.989 ],
                options: {},
                watch: function(value) {
                    return Stocks.one($routeParams.id).getList('sellStopLoss', {raise: value[0], sell: value[1]});
                }
            },
            sma: {
                label: 'SMA',
                settings: 5,
                options: {
                    showMarker: false,
                },
                watch: function(period) {
                    return Stocks.one($routeParams.id).one('sma').getList(period);
                }
            },
            rsi: {
                label: 'RSI',
                settings: 14,
                options: {
                    showMarker: false,
                    yaxis: 'y2axis',
                },
                watch: function(period) {
                    return Stocks.one($routeParams.id).one('rsi').getList(period);
                }
            },
            evalAndBest: {
                label: 'evalAndBest',
                settings: {
                    period: 30,
                    updown: 'up'
                },
                options: {},
                watch: function(settings) {
                    return Stocks.one($routeParams.id).one('evalAndBest').one(settings.updown).one('period').getList(settings.period);
                }
            },
            betterThan: {
                label: 'betterThan',
                settings: {
                    period: 30,
                    updown: 'up'
                },
                options: {},
                watch: function(settings) {
                    return Stocks.one($routeParams.id).one('betterThan').one(settings.updown).one('period').getList(settings.period);
                }
            },
            buySell: {
                label: 'buySell',
                settings: {
                    period: 30,
                    parallel: 'parallel',
                    resetOnResult: true,
                },
                options: {
                    pointLabels: {show: true}
                },
                watch: function(settings) {
                    if (settings.resetOnResult) {
                        return Stocks.one($routeParams.id).one('buySell', settings.parallel).one('resetOnResult').getList(settings.period);
                    } else {
                        return Stocks.one($routeParams.id).one('buySell', settings.parallel).getList(settings.period);
                    }
                }
            },
        }
        $scope.extra_graphs = [];
        function add(type) {
            var graph = {
                type: type,
                settings: angular.copy(graphFunctions[type].settings),
                options: _.extend({}, graphFunctions[type].options, {
                        show: false,
                        color: $scope.chartColors[$scope.extra_graphs.length + 2]
                    }),
                index: $scope.extra_graphs.length
            };
            var watch = function() {
                var promiss = graphFunctions[type].watch(graph.settings);
                promiss.then(function(data) {
                    graph.data = data;
                    graph.options.show = true;
                    $scope.series[graph.index + 2] = data;
                });
            }
            $scope.$watch(function() { return graph.settings }, _.debounce(watch, 100), true);
            $scope.chartOptions.series[graph.index + 2] = graph.options;
            $scope.extra_graphs.push(graph);
        }
        //add('sellStopLoss');
        //add('sma');
        //add('evalAndBest');
        $scope.graphFunctions = graphFunctions;
        $scope.addInfoGraph = function() {
            if (!_.isUndefined($scope.new_graph_type)) {
                add($scope.new_graph_type);
            }
        };
}]);

module.controller('TransactionHighlightController', ['$scope', '$timeout',
    function($scope, $timeout) {
        $scope.hoverTransaction = function(transaction) {
            var seriesIndex = 1;
            var plot = $('#chart').data('jqplot');
            var data = plot.series[seriesIndex].data;
            var pointIndex;
            var hl = plot.plugins.highlighter;
            var s = plot.series[seriesIndex];
            var smr = s.markerRenderer;
            var mr = hl.markerRenderer;
            mr.style = smr.style;
            mr.lineWidth = smr.lineWidth + hl.lineWidthAdjust;
            mr.size = smr.size + hl.sizeAdjust;
            var rgba = $.jqplot.getColorComponents(smr.color);
            var newrgb = [rgba[0], rgba[1], rgba[2]];
            var alpha = (rgba[3] >= 0.6) ? rgba[3]*0.6 : rgba[3]*(2-rgba[3]);
            mr.color = 'rgba('+newrgb[0]+','+newrgb[1]+','+newrgb[2]+','+alpha+')';
            mr.init();
            var date = new $.jsDate(transaction.book_date).getTime();
            for (pointIndex=0; pointIndex < data.length; pointIndex++) {
                if (data[pointIndex][0] == date) {
                    break;
                }
            }
            if (pointIndex == data.length) {
                return;
            }
            mr.draw(s.gridData[pointIndex][0], s.gridData[pointIndex][1], hl.highlightCanvas._ctx);
        };
        $scope.leaveTransaction = function(transaction) {
            var plot = $('#chart').data('jqplot');
            var hl = plot.plugins.highlighter;
            var ctx = hl.highlightCanvas._ctx;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        };
        $scope.jqplotDataClick = function($event, $params) {
            var seriesIndex = $params[0], pointIndex = $params[1], data = $params[2];
            var transaction;
            if (seriesIndex == 1) {
                transaction = _.find($scope.stock.transactions, {transaction_id: data.transaction.transaction_id});
                transaction.highlightRow = true;
                $timeout.cancel(data.highlightTimeout);
                data.highlightTimeout = $timeout(function() { transaction.highlightRow = false; }, 2000);
            }
        };
}]);

})();
