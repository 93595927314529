'use strict';

(function() {
angular.module('roDirectives', []).
  directive('pagination', function () {
    return {
      restrict: 'E',
      scope: {
        totalItems: '&',
        itemsPerPage: '=',
        currentPage: '=',
      },
      templateUrl: 'partials/pagination.html',
      link: function (scope, element, attrs) {
        scope.range = _.range;
        scope.$watch(scope.totalItems, function(){
            scope.numberOfPages = Math.ceil(scope.totalItems()/scope.itemsPerPage);
        });
      }
    };
  }).
  directive('navBar', ['$location', function($location) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            var $ul = $(element);
            $ul.addClass("nav navbar-nav");

            var $tabs = $ul.children();
            var tabMap = {};
            $tabs.each(function() {
              var $li = $(this);
              var href = $li.find('a').attr('href');
              if (href[0] == '#') {
                  //Substring 1 to remove the # at the beginning (because $location.path() below does not return the #)
                  href = href.substring(1);
              }
              tabMap[href] = $li;
            });

            scope.location = $location;
            scope.$watch('location.path()', function(newPath) {
                $tabs.removeClass("active");
                if (tabMap[newPath]) {
                    tabMap[newPath].addClass("active");
                }
            });
        }
    };
  }]).
  directive('infoBlock', [function() {
    return {
        restrict: 'A',
        link: function(scope, element) {
            var $e = $(element);
            var $toggle = $('<span class="info_block-toggle">?</span>')
            $e.before($toggle);
            $toggle.on('click', function() { $toggle.toggleClass('open'); });
        }
    };
  }]);
})();
