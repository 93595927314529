'use strict';

/* Home Controller */
(function() {
var module = angular.module('roControllers');

module.controller('TransactionListCtrl', ['$scope', 'Transactions', 'Stocks',
    function($scope, Transactions, Stocks) {
        var date = new $.jsDate();
        $scope.orderProp = 'name';
        $scope.transaction_types = {
            BUY: 'Buy',
            SELL: 'Sell',
            DIVIDEND: 'Dividend',
            INTEREST: 'Interest',
        };
        $scope.$watch('user.isAuthenticated', function() {
            $scope.transactions = Transactions.getList().$object;
            $scope.stocks = Stocks.getList().$object;
        });

        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.book_date_open = true;
        };
        $scope.add = {};
        $scope.addTransaction = function() {
            Transactions.post($scope.add).then(function(transaction) {
                $scope.transactions.push(transaction);
            });
        };

        $scope.login = {username:'', password:''};
        $scope.checkNewTransactions = function() {
            $scope.loading = true;
            Transactions.one('check_new_transactions').post('', $scope.login).then(function(transactions) {
                $scope.transactions = transactions;
                $scope.show_new_transaction_check = false;
                $scope.loading = false;
            }, function(response) {
                console.log("Error in check_new_transactions request", response);
                $scope.loading = false;
            });
        };
    }]);


})();
